@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap');

.App {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.Flashing-key {
  position: absolute;
  margin-left: 90vw;
  margin-top: 85vh;
  animation: flash 3s infinite;
}

@keyframes flash {
  0% {opacity: 1}
  50% {opacity: 0}
  100% {opacity: 1}
}

.Flashing-key img {
  width: 100px;
}

#container {
  position: relative;
}

#container canvas, #overlay {
  position:absolute;
  width:100vw;
  height:100vh;
}

#overlay {
  overflow: visible;
  display:table;
}

canvas {
  border: 1px solid black;
  z-index: 2;
}

h1 {
  font-family: 'Open Sans', sans-serif;
}

#name-and-desc {
  text-align: center;
  padding-bottom: 10vh;
}

.Outer-profile {
  margin-top: 20vh;
  width: 100vw;
  display: flex;
  margin-left:0%;
  float:left;
  position:relative;
  display:table-cell;
}

.Outer-work {
  position: absolute;
  overflow: visible;
  display: flex;
  width: 50vw;
  display: table-cell;
  right: 170vw;
  border: 5px black solid;
}

.Outer-projects {
  overflow: visible;
  display: flex;
  width: 50vw;
  display: table-cell;
  border: 5px black solid;
}


.Project-header {
  position:absolute;
  font-size: 4vw;
  top: 0;
  left: 15rem;
}

.Indiv-project {
  height: 10vh;
  display: table-cell;
}

.Indiv-work {
  height: 10vh;
  display: table-cell;
}

.Project-buttons {
  height: 50vh;
  position: absolute;
  margin-top: 12rem;
  left: 15rem;
}

.Project-buttons1 {
  height: 50vh;
  position: absolute;
  margin-top: 27rem;
  left: 15rem;
}

.Work-header {
  position:absolute;
  font-size: 4vw;
  top: 0;
  left: 15rem;
}

.Work-project {
  height: 10vh;
  display: table-cell;
}

.Work-buttons {
  height: 50vh;
  position: absolute;
  margin-top: 12rem;
  left: 15rem;
}

.Work-buttons1 {
  height: 50vh;
  position: absolute;
  margin-top: 27rem;
  left: 15rem;
}

.Arrow-blank {
  display: none;
}

#arrows {
  height:50vh;
}

.Right-arrow {
  position: relative;
  width: 150px;
  height: 100px;
  float: right;
  font-size: 1.3vh;
}

.Right-arrow img {
  width: 5vh;
}

.Left-arrow {
  position: relative;
  width: 200px;
  height: 100px;

  padding-left: 5vw;
  font-size: 1.3vh;
}

.Left-arrow img {
  width: 5vh;
}

.Down-arrow {
  position: relative;
  bottom: 5vh;
}

.Down-arrow img {
  width: 50px;
}

.Website-description {
  position:relative;
  text-align: left;
  display: inline-block;
  padding-left: 100px;
  padding-right: 100px;
  white-space: pre;
  font-size: 0.5vw;
  top: 40px;
}

.button {
  position: relative;
  border-radius: 0px;
  padding: 2vh 21px;
  height: 40px;
  vertical-align: center;
  font-size: 1.5vw;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
  border-bottom: 3px solid black;
  border-top: 3px solid black;
  text-decoration: none;
  color: black;
}

.button:hover {
  cursor: pointer;
  background: #1f1f1f;
  color: #fff;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
   -o-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
}

.MatterWorld {
  outline: none !important;
  overflow: hidden;
  position: absolute;
}

.Placeholder {
  opacity: 0;
}

.Profile-text {
  font-size: 4vw;
  text-align: right;
  font-family: 'Open Sans', sans-serif;
}

.Profile-text-div {
  vertical-align: middle;
  display: inline-block;
  white-space: pre;
  position:relative;
  animation: showup 7s 1;
  padding-right: 2%;
  padding-left: 2%;
  border-right: 5px solid black;
  border-bottom: 5px solid black;
}

.Profile-text-two {
  font-size: 2.4vw;
  text-align: left;
  animation: slidein 7s 1;
}

.Profile-two-div {
  vertical-align: middle;
  overflow: hidden;
  display: inline-block;
  white-space: pre;
  width: 37%;
  position:relative;
  animation: reveal 7s 1;
  padding-left: 2%;
}

@keyframes slidein {
    0% { margin-left:-10%; }
    20% { margin-left:-10%; }
    35% { margin-left:0%; }
    100% { margin-left:0%; }
}

@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:1;}
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    40% {width:37%;}
    80% {opacity:1;}
    100% {opacity:1;width:37%;}
}

.fade-out {
  white-space: pre-wrap;
  position:absolute;
  opacity: 0;
  transition: opacity 1s ease;
}
